import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Header from "../../Header";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Table from "components/Table/Table.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import avatar from "assets/img/faces/marc.jpg";
import ReactTable from "components/ReactTable/ReactTable";
import { log } from "debug/src/node";
import { Edit } from "@mui/icons-material";
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch";
import { Dialog, DialogActions, DialogContent,DialogTitle, IconButton } from "@material-ui/core";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "12px",
    textTransform: 'uppercase',
    marginTop: "0",
    marginBottom: "0",
    marginLeft: "20px",
    fontWeight: "300",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "5px",
    minHeight: "auto",
    fontSize: "16px",
    fontWeight: "300",
    fontFamily: "'Poppins', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    marginLeft: "20px",
    textDecoration: "none",
    textTransform: 'uppercase',

  },
};

const useStyles = makeStyles(styles);

export default function TypographyPage() {
  const classes = useStyles();
  const [newOrganization, setNewOrganization] = useState("");
  const [newOrganizationEmail, setNewOrganizationEmail] = useState("");
  const [newUserUsername, setNewUserUsername] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [schoolWebsite, setSchoolWebsite] = useState("");
  const [allOrgansizations, setAllOrganizations] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const columns = React.useMemo(
    () => [
      {
        Header: "School ID",
        accessor: "school_id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Website",
        accessor: "website",
      },
      {
        Header: "EOP",
        accessor: "toggle",
      },
      {
        Header: "Edit",
        accessor: "edit",
      },
    ],
    []
  );

  // Open the modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleAddNewOrganization = (e) => {
    e.preventDefault();
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      if (
        schoolId == "" && schoolName == "" && schoolWebsite == ""
      ) {
        toast.error("All fields are required", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        axios
          .post(
            process.env.REACT_APP_API_URL + "schools",
            {
              school_id: schoolId,
              name: schoolName,
              website: schoolWebsite,
            },
            { headers }
          )
          .then((response) => {
            if (response.data.code === 201) {
              toast.success(`${response.data.message}`, {
                position: toast.POSITION.TOP_CENTER,
              });
              getallOrganizations();
              setSchoolId("");
              setSchoolName("");
              setSchoolWebsite("");
            } else {
              toast.error(`${response.data.message}`, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          })
          .catch((error) => {
            toast.error("Failed to add organization", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  };
  const handleUpdateOrganization = (e) => {
    e.preventDefault();
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      if (
        schoolId == "" && schoolName == "" && schoolWebsite == ""
      ) {
        toast.error("All fields are required", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        axios
          .put(
            process.env.REACT_APP_API_URL + "schools",
            {
              school_id: schoolId,
              name: schoolName,
              website: schoolWebsite,
            },
            { headers }
          )
          .then((response) => {
            if (response.data.code === 201) {
              // toast.success(`${response.data.message}`, {
              //   position: toast.POSITION.TOP_CENTER,
              // });
              getallOrganizations();
              setSchoolId("");
              setSchoolName("");
              setSchoolWebsite("");
              setOpenModal(false);
            } else {
              // toast.error(`${response.data.message}`, {
              //   position: toast.POSITION.TOP_CENTER,
              // });
            }
          })
          .catch((error) => {
            toast.error("Failed to add organization", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    }
  };
  const handleOnChangeShoolStatus = (school_id) => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      axios.patch(process.env.REACT_APP_API_URL + "schools/" + school_id + "/access", { headers })
        .then((response) => {
          if (response.data.code == 200) {
            getallOrganizations();
            // toast.success(response.data.message, {
            //   position: toast.POSITION.TOP_CENTER,
            // });
          } else {
            // toast.error(response.data.message, {
            //   position: toast.POSITION.TOP_CENTER,
            // });
          }

        }).catch((error) => {
          console.log(error);

          toast.error("Failed to update organization", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };
  const getallOrganizations = () => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      fetch(process.env.REACT_APP_API_URL + "schools", { headers })
        .then((res) => res.json())
        .then((data) => {
          let arr = [];
          if (data.code == 200 && data?.schoolData?.length > 0) {
            // Map through schoolData to include ToggleSwitch directly
            const modifiedSchoolData = data?.schoolData?.map((school) => ({
              ...school,
              toggle: (
                <ToggleSwitch
                  value={school.school_id}
                  status={school.access_to_eop} // Set based on access_to_eop
                  handleChange={() => handleOnChangeShoolStatus(school.school_id)}
                />
              ),
              edit: (
                <IconButton
                  color="primary"
                  aria-label="edit"
                  onClick={() => {
                    setOpenModal(true);
setSchoolId(school.school_id);
setSchoolName(school.name);
setSchoolWebsite(school.website);
                  }}
                >
                  <Edit />
                </IconButton>
              )
            }));

            // Update state with modified data containing rendered ToggleSwitch
            setAllOrganizations(modifiedSchoolData);
          }
          // for (const key in data) {
          //   arr.push({organization:data[key].organization,type:data[key].type, status:data[key].status});
          // }
          // setAllOrganizations(arr);
          // console.log(allOrganizations);
        });
    }
  };
  useEffect(() => {
    getallOrganizations();
  }, []);
  return (
    <div>
      {currentUser.role === "Super Admin" ? <div>
        {/* <Header /> */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title=""
              headerColor="primary"
              tabs={[
                {
                  tabName: "Add Organization",
                  // tabIcon: Book,
                  tabContent: (
                    <Card>
                      <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>
                          Organization Profile
                        </h4>
                        <p className={classes.cardCategoryWhite}>
                          Complete Organization profile
                        </p>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={2}>
                            <CustomInput
                              labelText="School ID"
                              // id="company-disabled"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                // disabled: true,
                                value: schoolId,
                                onChange: (e) =>
                                  setSchoolId(e.target.value),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={1}></GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                              labelText="School Name"
                              // id="company-disabled"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                // disabled: true,
                                value: schoolName,
                                onChange: (e) =>
                                  setSchoolName(e.target.value),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={1}></GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                              labelText="School Website"
                              id="school-website"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                value: schoolWebsite,
                                onChange: (e) =>
                                  setSchoolWebsite(e.target.value),
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <br />

                      </CardBody>
                      <CardFooter>
                        <Button
                          color="secondary"
                          onClick={handleAddNewOrganization}
                        >
                          Add Organization
                        </Button>
                      </CardFooter>
                      <ToastContainer />
                    </Card>
                  ),
                },
                {
                  tabName: "All Organizations",
                  // tabIcon: Book,
                  tabContent: (
                    <div>
                      <div className={classes.searchWrapper}>
                        <ReactTable columns={columns} data={allOrgansizations} />
                      </div>
                      <Dialog open={openModal} onClose={handleCloseModal}>
                        <DialogTitle>Update Organization</DialogTitle>
                        <DialogContent>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <CustomInput
                                labelText="School Name"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  value: schoolName,
                                  onChange: (e) => setSchoolName(e.target.value),
                                }}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <CustomInput
                                labelText="School Website"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  value: schoolWebsite,
                                  onChange: (e) => setSchoolWebsite(e.target.value),
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseModal} color="secondary">
                            Cancel
                          </Button>
                          <Button onClick={handleUpdateOrganization} color="primary">
                            Update
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  ),
                },

              ]}
            />
          </GridItem>

        </GridContainer>
      </div> : <h1 style={{ textAlign: 'center' }}>Unauthorized page. Please contact admin</h1>}
    </div>
  );
}
